<template>
  <div class="add-transfer-stock">
    <KTCodePreview v-bind:title="'Thông tin phiếu chuyển kho'">
      <template v-slot:preview>
        <b-row class="pl-0">
          <b-col cols="6">
            <b-row>
              <b-form-group class="col-6 required-control">
                <label>Ngày chuyển kho:</label>
                <date-picker
                  placeholder="Chọn ngày chuyển kho"
                  class="mb-2 mt-2 form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateImport"
                ></date-picker>
              </b-form-group>
              <b-form-group class="col-6">
                <template>
                  <span>Tổng tiền:</span>
                </template>
                <p class="mt-2">{{ convertPrice(totalPrice) }}</p>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group class="col-6 required-control">
                <label>Kho nguồn:</label>
                <vue-autosuggest
                  class="border-radius-none mt-2"
                  aria-describedby="input-store-live-feedback"
                  :suggestions="filteredOptions"
                  @selected="onSelectedSourceStore"
                  :limit="200"
                  @input="onInputChangeSourceStore"
                  :input-props="inputSearchSourcewarehouse"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                  v-model="searchSourceStore"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.suggestionName }}
                    </div>
                  </div>
                </vue-autosuggest>
              </b-form-group>

              <b-form-group class="col-6 required-control">
                <label>Kho đích:</label>
                <vue-autosuggest
                  class="border-radius-none mt-2"
                  aria-describedby="input-store-live-feedback"
                  :suggestions="filteredOptions"
                  @selected="onSelectedDesStore"
                  :limit="200"
                  @input="onInputChangeDesStore"
                  :input-props="{
                    class: 'autosuggest__input',
                    placeholder: 'Chọn kho đích',
                    style: 'border-radius:0px!important',
                  }"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                  v-model="searchDesStore"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.suggestionName }}
                    </div>
                  </div>
                </vue-autosuggest>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group class="col-12">
                <template>
                  <span>Nội dung:</span>
                </template>
                <b-form-textarea
                  size="sm"
                  v-model="notes"
                  :placeholder="'Thêm nội dung...'"
                  :rows="3"
                  :max-rows="3"
                ></b-form-textarea>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col md="6">
            <TransferIntermediate
              :items="transferIntermediateItems"
              :editable="true"
            />
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col md="2" class="pr-0">
            <b-form-select
              class="select-style"
              v-model="selectTypeSearch"
              :options="listTypeSearch"
              size="sm"
              value-field="id"
              text-field="name"
              @change="onChangeTypeSearch()"
            ></b-form-select>
          </b-col>
          <b-col md="4" class="pl-0">
            <vue-autosuggest
              v-model="searchProduct"
              :suggestions="filteredOptionsProduct"
              @selected="onSelectedProduct"
              :limit="10"
              @input="onInputChangProduct"
              :input-props="inputSearchProductProp"
              :should-render-suggestions="
                (size, loading) => size >= 0 && !loading && searchProduct !== ''
              "
            >
              <div
                v-if="selectTypeSearch === 1"
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <div style="{ display: 'flex', color: 'navyblue'}">
                  {{ suggestion.item.productName }}
                </div>
              </div>
              <div
                v-else-if="selectTypeSearch === 2"
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <div>
                  {{ suggestion.item.productName }}-
                  <span class="text-warning"
                    >IMEI: {{ suggestion.item.imeiCode }}</span
                  >
                </div>
              </div>
            </vue-autosuggest>
          </b-col>
          <b-col md="6">
            <div class="d-flex justify-content-end">
              <b-button
                variant="secondary"
                size="sm"
                class="font-weight-bolder"
                @click="showModalImportProduct"
                :disabled="!sourceStoreSelected"
              >
                <i style="font-size: 1rem" class="far fa-file-excel"></i>Nhập SP
                từ excel
              </b-button>
            </div>
          </b-col>
        </b-row>
        <ImportExcelStockSlip
          ref="stock-slip-excel"
          :type="2"
          :storeId="sourceStoreSelected"
          v-on:validData="validData"
        />
        <span class="font-weight-bolder">Danh sách sản phẩm:</span>
        <table
          class="
            table table-bordered table-vertical-center table-hover
            col-12
            mt-4
          "
        >
          <thead>
            <tr>
              <th scope="col" class="title-center">Tên sản phẩm</th>
              <th scope="col" class="title-center" style="width: 5%">Tồn</th>
              <th scope="col" class="title-center">IMEI</th>
              <th scope="col" class="title-center">Số lượng</th>
              <th
                scope="col"
                class="title-center"
                v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
              >
                Đơn giá
              </th>
              <th
                scope="col"
                class="title-center"
                v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
              >
                Thành tiền
              </th>
              <th scope="col" class="title-center"></th>
            </tr>
          </thead>
          <tr v-if="listProductStock.length > 0">
            <td
              :colspan="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW') ? 3 : 3"
              class="tdTextAlignPrice"
            >
              Tổng
            </td>
            <td class="tdTextAlignPrice">{{ totalQuantity }}</td>
            <td
              class="tdTextAlignPrice"
              v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
            ></td>
            <td
              class="tdTextAlignPrice"
              v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
            >
              {{ convertPrice(totalPrice) }}
            </td>
            <td></td>
          </tr>
          <tbody v-for="item in listProductStock" :key="item.id">
            <TransferStock
              v-bind:productItem="item"
              v-on:save="save"
              v-on:deleteItem="deleteItemInArray"
              v-on:update="updateItem"
              v-bind:editState="true"
              v-bind:canViewOriginalPrice="
                checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')
              "
            />
          </tbody>
          <tr>
            <td
              :colspan="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW') ? 3 : 3"
              class="tdTextAlignPrice"
            >
              Tổng
            </td>
            <td class="tdTextAlignPrice">{{ totalQuantity }}</td>
            <td
              class="tdTextAlignPrice"
              v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
            ></td>
            <td
              class="tdTextAlignPrice"
              v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
            >
              {{ convertPrice(totalPrice) }}
            </td>
            <td></td>
          </tr>
        </table>
      </template>
      <template v-slot:foot>
        <b-button
          v-if="listProductStock.length > 0"
          style="font-weight: 600 !important; width: 70px; margin-right: 10px"
          variant="primary"
          size="sm"
          class="font-weight-bolder"
          @click="validate('save')"
        >
          Lưu
        </b-button>
        <b-button
          v-if="listProductStock.length > 0"
          variant="warning"
          size="sm"
          style="font-weight: 600 !important; width: 120px; margin-right: 10px"
          class="font-weight-bolder"
          @click="validate('save-approve')"
          v-show="checkPermission('STOCK_APPROVE_TRANSFER')"
        >
          Lưu và duyệt
        </b-button>
        <router-link
          to="/transfer-stock-to-nhanh"
          tag="button"
          v-if="listProductStock.length > 0"
        >
          <b-button
            style="font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            >Hủy</b-button
          >
        </router-link>
        <router-link
          to="/transfer-stock-to-nhanh"
          tag="button"
          v-if="listProductStock.length === 0"
        >
          <b-button
            style="font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            >Trở về</b-button
          >
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>

<style lang="scss">
.add-transfer-stock {
  .title-center {
    text-align: center;
  }
  .autosuggest__results ul {
    list-style: none;
    cursor: pointer;
    margin-bottom: 0;
  }

  .autosuggest__results {
    position: absolute;
    background-color: #fff;
    z-index: 1;
    width: 100%;
    border: 1px solid #e0e0e0;
    overflow: scroll;
    max-height: 200px;
    position: absolute;
  }

  .autosuggest__results-container {
    position: relative;
    width: 100%;
  }

  .autosuggest__input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: calc(1.35em + 1.1rem + 2px);
    padding: 0.55rem 0.75rem;
    font-size: 0.925rem;
    line-height: 1.35;
    border-radius: 0.28rem;
  }

  .autosuggest__input:focus {
    outline: none !important;
    border: 1px solid #92c5f9;
  }

  .autosuggest__results-item:hover {
    background-color: #ddd;
  }

  .autosuggest__results ul li {
    padding: 8px;
  }
  .tdTextAlignPrice {
    text-align: right !important;
    vertical-align: middle;
    font-weight: 600;
  }
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import TransferStock from '@/view/components/TransferStock';
import { unMaskPrice, convertPrice } from '@/utils/common';
import decounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import localData from '@/utils/saveDataToLocal';
import ImportExcelStockSlip from '@/view/components/stock-slips/ImportExcelStockSlip';
import { v4 } from 'uuid';
import Swal from 'sweetalert2';
import TransferIntermediate from '@/view/components/transfer-stockslips/TransferIntermediate';
import { getToastInstance } from '@/utils/toastHelper';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';

export default {
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      errors: [],
      dateImport: '',
      listInventories: [],
      sourceStoreSelected: null,
      desStoreSelected: null,
      totalPrice: 0,
      listProduct: [],
      notes: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
        {
          id: 2,
          name: 'Tìm theo IMEI',
        },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      selectedClassProduct: null,
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchSourceStore: '',
      searchDesStore: '',
      totalQuantity: 0,
      isSearching: false,
      inputSearchProductProp: {
        class: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      isNew: true,
      transferIntermediateItems: [],
      inputSearchSourcewarehouse: {
        class: 'autosuggest__input',
        placeholder: 'Chọn kho nguồn',
        style: 'border-radius:0px!important',
        disabled: false,
      },
    };
  },
  components: {
    KTCodePreview,
    TransferStock,
    ImportExcelStockSlip,
    TransferIntermediate,
  },
  created() {
    this.fetchStoreByUser();
    this.fetchStore();
  },
  mounted() {
    this.dateImport = moment().format('DD/MM/YYYY');
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu chuyển kho', route: '/transfer-stocks' },
      { title: 'Tạo phiếu chuyển kho' },
    ]);
    const warrantyIds = this.$route.query.warrantyIds;
    if (warrantyIds) {
      this.fetchWarrantyDetails(warrantyIds);
    }
  },
  methods: {
    convertPrice,
    ...getToastInstance(),
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      let imeiCode = '';
      let quantity = 0;
      this.searchProduct = option.item.productName;
      //check imei exist in list product
      if (this.selectTypeSearch === 2) {
        imeiCode = option.item.imeiCode;
        quantity = 1;
        if (!this.isExistImeiCode(imeiCode, this.listProductStock)) {
          let pro = {
            id: v4(),
            name: this.selectedClassProduct.productName,
            productCode: this.selectedClassProduct.productCode,
            barCode: this.selectedClassProduct.barCode,
            totalQuantityInStock:
              this.selectedClassProduct.totalQuantityInStock != null
                ? this.selectedClassProduct.totalQuantityInStock
                : 0,
            IMEI: imeiCode,
            proId: this.selectedClassProduct.id,
            price: this.selectedClassProduct.originalPrice,
            quantity: quantity,
            totalPrice: this.selectedClassProduct.originalPrice * quantity,
            productType: this.selectedClassProduct.productType,
          };
          this.listProductStock.unshift(pro);
          this.checkTotalMoney();
        } else {
          makeToastFaile('Mã IMEI đã được thêm vào danh sách!');
        }
      } else {
        if (this.selectedClassProduct.productType === 1) {
          quantity = 1;
          let index = this.listProductStock.findIndex(
            (item) => item.proId === this.selectedClassProduct.id
          );
          if (index === -1) {
            let pro = {
              id: v4(),
              name: this.selectedClassProduct.productName,
              productCode: this.selectedClassProduct.productCode,
              barCode: this.selectedClassProduct.barCode,
              totalQuantityInStock:
                this.selectedClassProduct.totalQuantityInStock != null
                  ? this.selectedClassProduct.totalQuantityInStock
                  : 0,
              IMEI: imeiCode,
              proId: this.selectedClassProduct.id,
              price: this.selectedClassProduct.originalPrice,
              quantity: quantity,
              totalPrice: this.selectedClassProduct.originalPrice * quantity,
              productType: this.selectedClassProduct.productType,
            };
            this.listProductStock.unshift(pro);
          } else {
            this.listProductStock[index].quantity =
              this.listProductStock[index].quantity + quantity;
          }
          this.checkTotalMoney();
        } else if (this.selectedClassProduct.productType === 2) {
          let pro = {
            id: v4(),
            name: this.selectedClassProduct.productName,
            productCode: this.selectedClassProduct.productCode,
            barCode: this.selectedClassProduct.barCode,
            totalQuantityInStock:
              this.selectedClassProduct.totalQuantityInStock != null
                ? this.selectedClassProduct.totalQuantityInStock
                : 0,
            IMEI: imeiCode,
            proId: this.selectedClassProduct.id,
            price: this.selectedClassProduct.originalPrice,
            quantity: quantity,
            totalPrice: this.selectedClassProduct.originalPrice * quantity,
            productType: this.selectedClassProduct.productType,
          };
          this.listProductStock.unshift(pro);
        }
      }
      this.searchProduct = '';
      this.checkTotalMoney();
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    createStock: async function (path) {
      if (this.searchSourceStore.trim() === '') {
        this.sourceStoreSelected = null;
      }
      if (this.searchDesStore.trim() === '') {
        this.desStoreSelected = null;
      }
      if (this.desStoreSelected === null) {
        makeToastFaile('Vui lòng chọn kho đích!');
        return;
      }
      if (this.sourceStoreSelected === null) {
        makeToastFaile('Vui lòng chọn kho nguồn!');
        return;
      }
      if (this.listProductStock.length === 0) {
        makeToastFaile('Vui lòng chọn sản phẩm cần chuyển kho!');
        return;
      }

      const nullItems = this.transferIntermediateItems.filter(
        (item) => !item.storeName || !item.inChargeEmployeeName
      );

      if (nullItems.length > 0) {
        return makeToastFaile('Vui lòng nhập thông tin kho trung gian!');
      }

      const listPro = this.listProductStock.map((element) => {
        return {
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.price),
        };
      });
      const data = {
        sourceStoreId: this.sourceStoreSelected,
        desStoreId: this.desStoreSelected,
        description: this.notes,
        transferDate: this.dateImport
          ? moment(this.dateImport, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        listProduct: listPro,
        transferIntermediateItems: this.transferIntermediateItems,
      };
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.post(`stock-slips/${path}`, data)
          .then(({ data }) => {
            if (data.status === 1) {
              makeToastSuccess(data.message);
              this.$router.push({
                name: 'update-transfer-stock',
                query: { id: data.data },
              });
            } else {
              makeToastFaile(data.message);
              setTimeout(() => {
                this.isNew = true;
              }, TIME_TRIGGER);
            }
          })
          .catch((response) => {
            makeToastFaile(response);
            setTimeout(() => {
              this.isNew = true;
            }, TIME_TRIGGER);
          });
      }
    },
    save(item) {
      this.listProduct[0] = item;
      this.totalPrice = 0;
      this.checkTotalMoney();
    },
    checkTotalMoney() {
      this.totalPrice = 0;
      this.totalQuantity = 0;
      this.listProductStock.forEach((element) => {
        let total = element.quantity * unMaskPrice(element.price);
        this.totalPrice += total;
        this.totalQuantity += parseInt(element.quantity);
      });
    },
    fetchStore() {
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
        this.listInventories.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
        });
        let tmp = [{ data: this.options[0].data }];
        this.filteredOptions = tmp;
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      this.listProductSearch = [];
      ApiService.get(
        `stocks/get-product-by-store/withMerchant?storeId=${this.sourceStoreSelected}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          const nextSearch = this.searchProduct;
          this.fetchProduct(nextSearch);
        }
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
      this.totalPrice = 0;
      this.checkTotalMoney();
    },
    updateItem(item) {
      this.listProduct.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProduct[index].name = item.name;
          this.listProduct[index].IMEI = item.IMEI;
          this.listProduct[index].proId = item.proId;
          this.listProduct[index].price = item.price;
          this.listProduct[index].quantity = parseInt(item.quantity);
          this.listProduct[index].totalPrice = item.totalPrice;
        }
      });
      this.totalPrice = 0;
      this.checkTotalMoney();
    },
    validate(mode) {
      this.errors = [];
      if (!this.dateImport) {
        this.errors.push('Thiếu ngày chuyển kho');
      }
      if (this.sourceStoreSelected == null) {
        this.errors.push('Thiếu kho nguồn');
      }
      if (this.desStoreSelected == null) {
        this.errors.push('Thiếu kho đích');
      }
      if (this.listProductStock.length == 0) {
        this.errors.push('Đang không có sản phẩm nào để nhập kho');
      }

      if (this.errors.length == 0) {
        if (mode === 'save') {
          this.createStock('transfer-stock-slip');
        } else if (mode === 'save-approve') {
          this.showWarningAlert();
        }
      } else {
        this.errors.forEach((element) => {
          makeToastFaile(element);
        });
      }
    },
    onChangeStore() {
      this.listProductStock = [];
    },
    fetchProductImei(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      ApiService.get(
        `stocks/get-product-by-store-imei/withMerchant?storeId=${this.sourceStoreSelected}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        this.listProduct = products;
        if (products.length === 1) {
          this.selectedClassProduct = products[0];
          let pro = {
            id: v4(),
            name: this.selectedClassProduct.productName,
            productCode: this.selectedClassProduct.productCode,
            barCode: this.selectedClassProduct.barCode,
            totalQuantityInStock:
              this.selectedClassProduct.totalQuantityInStock != null
                ? this.selectedClassProduct.totalQuantityInStock
                : 0,
            IMEI: this.selectedClassProduct.imeiCode,
            proId: this.selectedClassProduct.id,
            price: this.selectedClassProduct.originalPrice,
            quantity: 1,
            totalPrice: this.selectedClassProduct.originalPrice,
            productType: this.selectedClassProduct.productType,
          };
          this.listProductStock.unshift(pro);
          this.checkTotalMoney();
          this.searchProduct = '';
          this.isSearching = false;
        } else {
          products.map((element) => {
            this.optionsProduct[0].data.push(element);
          });
          this.filteredOptionsProduct = [
            {
              data: this.optionsProduct[0].data,
            },
          ];
          this.isSearching = false;
          if (textSearch !== this.searchProduct) {
            const nextSearch = this.searchProduct;
            this.fetchProductImei(nextSearch);
          }
        }
      });
    },
    isExistImeiCode(imeiCode, listProduct) {
      let check = false;
      for (let index = 0; index < listProduct.length; index++) {
        const element = listProduct[index];
        let listImei = element.IMEI.replace(/[\n\r]/g, ', ').split(',');
        for (let i = 0; i < listImei.length; i++) {
          const ele = listImei[i].trim();
          if (ele === imeiCode) {
            check = true;
            break;
          }
        }
        if (check) {
          break;
        }
      }
      return check;
    },
    onSelectedSourceStore(option) {
      this.searchSourceStore = option.item.name;
      this.sourceStoreSelected = option.item.id;
      this.inputSearchProductProp.disabled = false;
      this.onChangeStore();
    },
    onInputChangeSourceStore(text) {
      this.searchSourceStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedDesStore(option) {
      this.searchDesStore = option.item.name;
      this.desStoreSelected = option.item.id;
    },
    onInputChangeDesStore(text) {
      this.searchDesStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    searchProductAPI() {
      let textSearch = this.searchProduct ? this.searchProduct.trim() : '';
      if (!this.isSearching && textSearch.length > 3) {
        if (this.selectTypeSearch === 2) {
          this.fetchProductImei(textSearch);
        } else {
          this.fetchProduct(textSearch);
        }
      }
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.searchSourceStore = data.data.name;
          this.sourceStoreSelected = data.data.id;
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.sourceStoreSelected = stores[0].id;
            this.searchSourceStore = stores[0].name;
            this.inputSearchSourcewarehouse.disabled = true;
          }
        }
        this.inputSearchProductProp.disabled = false;
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onChangeTypeSearch() {
      this.optionsProduct[0].data = [];
    },
    showModalImportProduct() {
      this.$refs['stock-slip-excel'].showModal();
    },
    validData(data) {
      data.forEach((element) => {
        const item = {
          id: element.id,
          name: element.productName,
          productCode: element.productCode,
          barCode: element.barCode,
          totalQuantityInStock: element.totalQuantityInStock,
          IMEI: element.imeiCode,
          proId: element.productId,
          price: element.unitPrice,
          quantity: element.quantity,
          discount: element.discountAmount,
          totalPrice: element.totalAmount,
          productType: element.productType,
        };
        this.listProductStock.push(item);
      });
    },
    showWarningAlert: function () {
      Swal.fire({
        title: 'Lưu và duyệt phiếu chuyển kho!',
        text: 'Bạn có chắc muốn lưu và duyệt phiếu chuyển kho này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Có',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.createStock('create-approve-stock-slip');
        }
      });
    },
    fetchWarrantyDetails(warrantyIds) {
      const params = {
        ids: warrantyIds,
      };
      ApiService.query(`warranty-stock/details/by-warranty`, { params }).then(
        ({ data }) => {
          const stockSlipDetails = data.data;
          this.listProductStock = stockSlipDetails.map((stockSlipDetail) => {
            return {
              id: v4(),
              name: stockSlipDetail.productName,
              productCode: stockSlipDetail.productCode,
              barCode: stockSlipDetail.barCode,
              totalQuantityInStock: 0,
              IMEI: stockSlipDetail.productImei.replace(', ', '\n'),
              proId: stockSlipDetail.productId,
              price: stockSlipDetail.unitPrice,
              quantity: stockSlipDetail.quantity,
              discount: 0,
              totalPrice: stockSlipDetail.totalAmount,
              productType: stockSlipDetail.productType,
            };
          });
        }
      );
    },
  },
};
</script>
